<template>
  <div class="row content-wrapper">
    <h1 class="auth-title">비밀번호찾기</h1>
    <div class="col-md-12 auth-wrapper">
      <form class="form" @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="email" class="form-label">이메일</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="email"
            placeholder="이메일을 입력해주세요."
          />
        </div>
        <div class="mb-5 text-center">
          <button
            :disabled="!isEmailValid"
            type="submit"
            class="btn btn-default auth-submit-btn"
          >
            비밀번호찾기
          </button>
        </div>
      </form>
    </div>
    <div class="col-md-12 text-center">
      <span class="auth-link-text">아직 제주투브이알 회원이 아니신가요?</span>
      <router-link to="/register" class="auth-link">회원가입</router-link>
    </div>
    <div class="col-md-12 text-center">
      <span class="auth-link-text">이미 제주투브이알 회원이신가요?</span>
      <router-link to="/login" class="auth-link">로그인</router-link>
    </div>
  </div>
</template>

<script>
import { validateEmail } from '../../utils/validation.js';

export default {
  data() {
    return {
      email: '',
      emailErrorLog: '',
    };
  },
  computed: {
    isEmailValid() {
      return validateEmail(this.email);
    },
  },
  methods: {
    async submitForm() {
      try {
        const userData = {
          email: this.email,
        };

        await this.$store.dispatch('LOGIN', userData);
        // this.$router.push('/');
        this.$toast.success(
          '비밀번호 재설정 이메일을 성공적으로 전달했습니다.'
        );
      } catch (error) {
        this.$toast.error(
          '일시적으로 문제가 생겼습니다. 관리자에게 문의해주세요.'
        );
      }
    },
  },
};
</script>

<style scoped>
.auth-title {
  margin: 0;
  font-size: 30px;
  color: #333;
}
.auth-wrapper {
  padding-top: 40px;
}
.form-label {
  font-size: 18px;
  color: #333;
}
.auth-submit-btn {
  width: 250px;
  height: 60px;
  border-radius: 3px;
  background-color: #333;
  color: #fff;
}
.auth-link-text {
  padding-right: 10px;
  font-size: 16px;
}
.auth-link {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-decoration: underline !important;
}
</style>
