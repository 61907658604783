<template>
  <ForgotPasswordComponent />
</template>

<script>
import ForgotPasswordComponent from '@/components/auth/ForgotPasswordComponent.vue';

export default {
  components: {
    ForgotPasswordComponent,
  },
};
</script>

<style></style>
